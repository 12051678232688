import logger from 'OK/logger';
import { parent } from 'OK/utils/dom';
import viewportTracker from 'OK/ViewportTracker';

var MODES = {
        standard : 'standard',
        hover : 'hover'
    };

function initFrames(framesCount, repeats, width) {
    var frames = [],
        i,
        x,
        s,
        repeat,
        j;

    for (i = 0; i < framesCount; i++) {
        x = - Math.round(i * width);
        s = x + 'px 0';

        if (!repeats[i]) {
            frames.push(s);
        } else {
            repeat = repeats[i];
            for (j = 0; j < repeat; j++) {
                frames.push(s);
            }
        }
    }

    return frames;
}

function getElementWidth(element) {
    var styleWidth = getComputedStyle(element).width;
    if (styleWidth) {
        return parseFloat(styleWidth.replace('px', ''));
    }
    return element.clientWidth || 0;
}

function prefetch(imageUrl) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = imageUrl;
    });
}

export default class SpriteAnimation {
    constructor() {
        this.isPaused = true;
        this.lastFrameTime = 0;
        this.frameID = 0;
        this.bgPos = 0;
        this.lastPos = 0;
        this.active = 0;
    }

    activate(element) {
        var self = this, repeatsAttrValue = element.getAttribute('data-framerepeats'),
            repeats = repeatsAttrValue ? JSON.parse(repeatsAttrValue) : {};

        self.element = element;
        self.mode = element.getAttribute('data-mode') || MODES.standard;
        self.width = Math.round(getElementWidth(element));
        self.fps = parseInt(element.getAttribute('data-fps'), 10) || 24;
        self.replayDelay = parseInt(element.getAttribute('data-replaydelay'), 10) || 0;
        self.frameDuration = (1000 / this.fps) + 1;
        self.bindedRender = this.render.bind(this);
        self.previewImage = this.element.style.backgroundImage;
        self.spriteImage = element.getAttribute('data-image');

        return prefetch(self.spriteImage).then(function (e) {

            var img = e.target, framesCount = element.getAttribute('data-frames');

            if (framesCount === 'auto') {
                framesCount = Math.round(img.width / self.width);
            } else {
                framesCount = parseInt(framesCount, 10) || 60;
            }

            self.frames = initFrames(framesCount, repeats, self.width);

            if (self.mode === MODES.hover) {
                var hoverElement = parent(self.element, 'js-hover-container');
                if (hoverElement && hoverElement !== document) {
                    hoverElement.addEventListener('mouseover', self);
                    hoverElement.addEventListener('mouseout', self);
                    self.hoverElement = hoverElement;
                }
            } else {
                viewportTracker.add(self.element, function (inViewport) {
                    if (inViewport) {
                        self.play();
                    } else {
                        self.pause();
                    }
                });
            }
        }, function (e) {
            logger.clob('error', self.spriteImage, 'prefetch', 'sprite');
            throw e;
        });
    }

    setSpriteImage() {
        var size = this.width;
        this.element.style.backgroundImage = 'url(' + this.spriteImage + ')';
        this.element.style.backgroundSize = (this.frames.length * size) + 'px ' + size + 'px';
        this.element.style.backgroundPosition = '0 0';
    }

    setPreviewImage() {
        this.element.style.backgroundImage = this.previewImage;
        this.element.style.backgroundSize = null;
        this.element.style.backgroundPosition = null;
    }

    deactivate() {
        this.stop();
        clearTimeout(this.delayTimer);

        if (this.hoverElement) {
            this.hoverElement.removeEventListener('mouseover', this);
            this.hoverElement.removeEventListener('mouseout', this);
        }

        if (this.mode !== MODES.hover) {
            viewportTracker.remove(this.element);
        }
    }

    handleEvent(e) {
        if (e.type === 'mouseout') {
            this.pause();
        } else if (e.type === 'mouseover') {
            this.play();
        }
    }

    play() {
        this.setSpriteImage();
        if (!this.frameID) {
            this.isPaused = false;
            this.frameID = requestAnimationFrame(this.bindedRender);
        }
    }

    pause() {
        if (!this.isPaused) {
            cancelAnimationFrame(this.frameID);
            this.frameID = 0;
            this.lastFrameTime = 0;
            this.isPaused = true;
        }
    }

    stop() {
        this.pause();
        this.setPreviewImage();
        this.active = 0;
    }

    render() {
        this.frameID = requestAnimationFrame(this.bindedRender);
        var time = Date.now();
        if (this.fps < 60 && this.lastFrameTime && this.lastFrameTime + this.frameDuration > time) {
            return;
        }
        this.lastFrameTime = time;

        this.tick();
        this.bgPos = this.frames[this.active];
        if (this.bgPos !== this.lastPos) {
            this.element.style.backgroundPosition = this.lastPos = this.bgPos;
        }
    }

    tick() {
        this.active++;
        if (this.active >= this.frames.length) {
            var replayDelay = this.replayDelay, self = this;
            if (replayDelay !== 0) {
                this.pause();
                clearTimeout(this.delayTimer);
                this.delayTimer = setTimeout(function () {
                    self.play();
                }, replayDelay);
            }
            this.active = 0;
        }
    }
}
